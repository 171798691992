import { debounce } from "@/utils/bouncer";
import type { Voucher } from "@paparazzi/types/paparazzi.api.viewsets.vouchers";
import { createLogger } from "@paparazzi/utils/debug";
import { captureMessage } from "@sentry/vue";
import api from "@virgodev/bazaar/functions/api";
import copy from "@virgodev/bazaar/functions/copy";
import { useLocalStorageStore } from "@virgodev/bazaar/functions/localstorage/store";
import { defineStore } from "pinia";
import { computed, nextTick, ref, watch, type Ref } from "vue";
import { useCartStore } from "./cart";
import type { Promotion } from "./defs/shop_defs";
import { useOrderStore } from "./orders";
import { useShopStore } from "./shop";
import { useUserStore } from "./user";

const log = createLogger("promos");

export const usePromosStore = defineStore("promos", () => {
  const user = useUserStore();
  const cart = useCartStore();
  const shop = useShopStore();
  const orders = useOrderStore();
  const storage = useLocalStorageStore();

  const applicableVolume: Ref<{ [key: string]: number }> = ref({});
  const couponCode = ref<string>();
  const couponPromo = ref<number>();
  const vouchers = ref<Voucher[]>(storage.get("voucher-list", []));
  const vouchersUpdated = ref(storage.get("voucher-list-time", 0));
  const lastApplication = ref(new Date(-1));

  const qualified = computed(() => {
    shop.promos.forEach((promo) => {
      applicableVolume.value[promo.id] = getApplicableVolume(promo);
    });

    return shop.promos.filter((promo) => {
      return isPromoValid(promo);
    });
  });

  watch(
    () => cart.object.coupon_code,
    () => {
      if (cart.object.coupon_code && !couponCode.value) {
        getCouponPromo(cart.object.coupon_code);
      } else {
        couponCode.value = "";
        couponPromo.value = undefined;
      }
    },
  );

  watch(couponPromo, () => {
    // reset application
    console.warn("reseting coupon promo");
    lastApplication.value = new Date(-1);
  });

  function getApplicableVolume(promo: Promotion) {
    let volume = 0;
    let limitedToCategories: number[] = [];
    let limitedToSkus: string[] = [];

    if (promo.limit_categories && promo.limit_categories.length > 0) {
      limitedToCategories = promo.limit_categories;
    } else if (promo.limit_to) {
      limitedToSkus = promo.limit_to.split(/,/g).map((a) => a.trim());
    }

    for (let item of cart.items) {
      if (!item.promotion) {
        let product = shop.products.find((p) => item.product?.id === p.id);
        if (product) {
          let included = false;
          if (limitedToCategories.length > 0) {
            included = limitedToCategories.includes(product.category);
          } else if (limitedToSkus.length > 0) {
            included = limitedToSkus.includes(product.remote_id);
          } else {
            included = true;
          }
          if (included) {
            volume += parseFloat(product.volume) * item.quantity;
          }
        }
      }
    }
    return volume;
  }

  function isPromoValid(promo: Promotion, ignoreCode = false): boolean {
    let ingroup = !promo.group || user.groups.indexOf(promo.group) > -1;
    let included =
      !promo.exclude_group || user.groups.indexOf(promo.exclude_group) === -1;
    let max = promo.max_volume;
    let mintotal = null;
    let maxtotal = null;
    if (promo.filters) {
      mintotal = promo.filters.find((f) => f.module == "cart_total");
      maxtotal = promo.filters.find((f) => f.module == "cart_total_max");
    }
    const cPromo = ignoreCode ? promo.id : couponPromo.value;
    log(
      promo.id,
      promo.description,
      promo.coupon_code,
      promo.has_voucher,
      (!promo.coupon_code && !promo.has_voucher) || promo.id === cPromo,
      applicableVolume.value[promo.id] >= (promo.min_volume || 0),
      !max || max > applicableVolume.value[promo.id],
      ingroup,
      included,
      !mintotal || cart.subtotal >= mintotal.value,
      !maxtotal || cart.subtotal <= maxtotal.value,
    );
    return (
      ((!promo.coupon_code && !promo.has_voucher) || promo.id === cPromo) &&
      applicableVolume.value[promo.id] >= (promo.min_volume || 0) &&
      (!max || max > applicableVolume.value[promo.id]) &&
      ingroup &&
      included &&
      (!mintotal || cart.subtotal >= mintotal.value) &&
      (!maxtotal || cart.subtotal <= maxtotal.value)
    );
  }

  async function applyPromotions() {
    await nextTick();
    const dt = new Date(cart.object?.changed || -1);
    if (!lastApplication.value || lastApplication.value < dt) {
      lastApplication.value = dt;

      if (cart.object.coupon_code) {
        await getCouponPromo(cart.object.coupon_code);
      }
      // remove all promo gift items
      const userChoicePromos: number[] = qualified.value
        .filter((q) => q.promo_type === "local_userchoice")
        .map((q) => q.id)
        .filter((q) => q);

      cart.object.items = cart.items.filter((i) => {
        return (
          !i.promotion ||
          userChoicePromos.includes(i.promotion) ||
          i.promotion === couponPromo.value
        );
      });
      cart.object.shippingPromos = undefined;

      const shippingPromos = new Set();
      for (const promo of qualified.value) {
        let sku = null;
        const av = applicableVolume.value[promo.id] || 0;
        if (av >= promo.volume) {
          let valid = true;
          for (let filter of promo.filters || []) {
            if (filter.module === "in_birth_month") {
              // are we missing a birth month?
              if (user.props.rep_number && !user.props.b_month) {
                await user.pull();
              }

              // DEBUG: remove after feb 2025
              // still missing a birth month?
              if (user.props.rep_number && !user.props.b_month) {
                console.error("birthday promo failed to get b-month!");
                captureMessage("birthday promo failed to get b-month!", {
                  user: user.props,
                  promo: promo,
                });
              }

              valid =
                valid &&
                new Date().getMonth() + 1 === parseInt(user.props.b_month);
              log(
                "in birth month",
                new Date().getMonth() + 1,
                user.props.b_month,
              );
            } else if (filter.module === "first_order") {
              log("checking if this is the first order");
              await orders.maybeSync();
              log(" - orders", orders.list);
              valid = valid && orders.list.length === 0;
            } else if (
              filter.module === "only_once_yearly" ||
              filter.module === "ship_once_yearly" ||
              filter.module === "combined_order" ||
              filter.module === "combined_over"
            ) {
              // break;
              if (filter.id) {
                valid = valid && (await testPromotionFilter(filter.id));
                log(promo.description, "online-test", filter.module, valid);
              } else {
                valid = false;
                log(promo.description, filter.module, valid);
                break;
              }
            }
          }

          log("valid?", promo.description, valid);

          // DEBUG: will need to remove this after Feb 2025
          if (
            !valid &&
            promo.id === 133 &&
            user.props.rep_number &&
            user.props.b_month === 2
          ) {
            console.error("user was not awarded birthday gift!");
            captureMessage("user wasn't awarded birthday gift", {
              user: user.props,
              promo: promo,
            });
          }

          if (valid) {
            if (promo.promo_type === "local_gift") {
              sku = promo.limit_freebies_to.toLowerCase();
            } else if (
              promo.promo_type === "local_indexed_gift" ||
              promo.promo_type === "local_batched"
            ) {
              const index = Math.floor(av / promo.volume);
              sku =
                promo.limit_freebies_to.toLowerCase() +
                index.toString().padStart(2, "0");
            }
            if (promo.shipper) {
              shippingPromos.add(promo.id);
            }
          }
        }

        if (sku) {
          const product = shop.objects.products.find(
            (p) => p.remote_id.toLowerCase() === sku,
          );

          // is this already in the cart?
          let cartitem = null;
          if (product) {
            cartitem = cart.items.find((item) => {
              return (
                item.product?.id === product.id && item.promotion === promo.id
              );
            });
          }

          let quantity = 1 - (cartitem?.quantity || 0);
          if (promo.promo_type === "local_gift" && promo.cumulative) {
            quantity = Math.floor(
              (av - (promo.cumulative_offset || 0)) / promo.volume,
            );
            if (promo.cumulative_max) {
              quantity = Math.min(quantity, promo.cumulative_max);
            }
          }

          // if (!product) {
          //   console.log("getting missing item?", sku);
          //   product = await checkForProduct(sku);
          // }

          if (product && quantity !== 0 && !product.extras?.eventforms) {
            const promoItem = shop.objects.products.find(
              (p) => p.remote_id.toLowerCase() === sku.toLowerCase(),
            );
            if (promoItem) {
              cart.object.items.push({
                // id: -1,
                product: copy(promoItem),
                quantity: quantity,
                promotion: promo.id,
                promotion_name: promo.description,
                extras: null,
              });
            } else {
              console.warn("missing promo item", sku);
            }
          } else {
            console.warn("item missing", sku, product);
          }
        }
      }

      // update shipping promos
      if (shippingPromos.size > 0) {
        cart.object.shippingPromos = Array.from(shippingPromos) as Promotion[];
      }
    } else {
      log(
        "skipping apply promo",
        cart.object.changed,
        lastApplication.value.toISOString(),
      );
    }
  }

  async function testPromotionFilter(filter_id: number) {
    if (cart.object.id) {
      const key = `promo-filter-u:${user.props.id}-id:${filter_id}-v:3`;
      let isValid = storage.get(key, null);
      if (
        !isValid ||
        new Date(isValid.created).getTime() <
          Date.now() - 2 * 24 * 60 * 60 * 1000
      ) {
        const response = await api({
          url: "promos/filter/",
          json: {
            filter: filter_id,
            cart: cart.object.id,
          },
          method: "POST",
        });
        storage.put(key, response.body);
        isValid = response.body;
      }
      console.log("response", isValid, "valid" in isValid);
      if ("valid" in isValid) {
        return isValid.valid;
      }
      return false;
    }
    return false;
  }

  async function fetchVouchers() {
    if (
      Date.now() - vouchersUpdated.value >= 1000 * 60 * 60 * 24 &&
      !cart.object.coupon_code
    ) {
      vouchers.value = [];
      const response = await api({ url: "vouchers/" });
      if (response.ok) {
        vouchers.value = response.body.results;
        storage.put("voucher-list", vouchers.value);
        storage.put("voucher-list-time", Date.now());
      }
    }
  }

  async function getVoucher(): Promise<
    { voucher: Voucher; promo: Promotion | undefined } | undefined
  > {
    if (vouchers.value.length > 0) {
      // && !cart.object.coupon_code) {
      if (await debounce("get-vouchers")) {
        const voucher = vouchers.value.find((voucher) => {
          const promo = shop.objects.promos.find(
            (promo) => promo.id === voucher.promo,
          );
          if (promo) {
            applicableVolume.value[promo.id] = getApplicableVolume(promo);
            const valid = isPromoValid(promo, true);
            return valid;
          }
          return false;
        });
        const promo = shop.objects.promos.find(
          (promo) => promo.id === voucher?.promo,
        );
        if (voucher && promo) {
          return {
            voucher,
            promo,
          };
        }
      }
    }
    return;
  }

  async function getCouponPromo(code: string): Promise<string> {
    let response = await api({
      url: `cart/coupon/`,
      json: { code },
      method: "POST",
    });
    if (response.ok && response.body.status == "ok") {
      couponCode.value = code;
      couponPromo.value = response.body.promo;
      return response.body.promo;
    }
    return "";
  }

  async function setup() {
    // vouchersUpdated.value = ;
    if (cart.object.coupon_code) {
      await getCouponPromo(cart.object.coupon_code);
    }
  }

  async function clear() {
    vouchers.value = [];
    vouchersUpdated.value = 0;
  }

  return {
    qualified,
    couponCode,
    couponPromo,
    vouchers,
    fetchVouchers,
    getVoucher,
    getCouponPromo,
    applyPromotions,
    setup,
    clear,
  };
});
